import React from "react"
import {Link, StaticQuery, graphql} from "gatsby"
import FPLayout from "../components/fplayout"
import SEO from "../components/seo"
import {Container, Row, Col, Jumbotron, Button} from "reactstrap"
import {StaticImage} from "gatsby-plugin-image"
import styled from "styled-components"
import {colors, fonts} from "../../theme.js"
import wizard from "../images/wizard.svg"

const BackgroundSection = ({className}) => (
  <StaticQuery
    query={graphql`{
  desktop: file(relativePath: {eq: "background.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        quality: 80
        tracedSVGOptions: {background: "#290849", color: "#ffd524"}
        placeholder: TRACED_SVG
        layout: FULL_WIDTH
      )
    }
  }
}
`}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.gatsbyImageData
      return (
        <div style={{ display: "grid", background: `#040e18` }}>
          <StaticImage
          as="section"
          src="../../src/images/background.jpg"
          alt="Sparkfiles"
          layout="fullWidth"
          placeholder="dominantColor"
          quality={80}
          backgroundColor={`#040e18`}
          aspectRatio={3 / 1}
          style={{ gridArea: "1/1" }}
          />
          <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="text-center fpjumbo px-0 pb-0">
            <img src={wizard} height="400px" alt="Sparkfiles Wizard" />
          </div>
          <Container>
            <Row>
              <Col>
                <CallToAction>
                  <CallToActionHeading>
                    <span style={{color: colors.meatFire}}>Welcome.</span>
                  </CallToActionHeading>
                  <hr className="my-2" />
                  <p style={{fontSize: `1.25em`}}>
                    Want to learn more about the fascinating origin of words,
                    phrases and customs?
                    <br />{" "}
                    <b>
                      See our{" "}
                      <span style={{color: colors.meatRare}}>Spark Files</span>.
                    </b>
                  </p>
                  <p className="lead">
			  <Link to="/blog/">
                      <Button className="jumbobutton py-3" size="lg">
                        Enter
                      </Button>
                    </Link>
                  </p>
                </CallToAction>
              </Col>
            </Row>
          </Container>
        </div>
        </div>
      )
    }}
  />
)

const FrontPage = () => (
  <FPLayout>
    <SEO
      title="Spark Files | Exploring The Origin Of Words, Phrases And Customs"
      image="https://sparkfiles.net/og-image.jpg"
      url="https://sparkfiles.net"
    />
    <Container fluid={true}>
      <Row>
        <Col className="px-0 fpbg ">
          <StyledBackgroundSection />
        </Col>
      </Row>
    </Container>
  </FPLayout>
)

export default FrontPage

const CallToAction = styled.div`
  border-radius: 5px;
  background: ${colors.meatExtrachar};
  color: #fff;
  margin-bottom: -120px;
  position: relative;
  top: -150px;
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  text-align: center;
  min-height: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
`
const CallToActionHeading = styled.h1`
  font-family: ${fonts.mainFont};
  font-size: 2.5rem;
`
const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`
