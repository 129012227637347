/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql} from "gatsby"

import Header from "./header"
import Footer from "./footer"
import FooterSM from "./sm-footer"

import "../styles/styles.scss"

const FPLayout = ({children}) => (
  <StaticQuery
    query={graphql`
      query SiteNameQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <FooterSM />
        <Footer />
      </>
    )}
  />
)

FPLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FPLayout
